import axios from "axios";
import { addToBaseURL } from "@/test-utils/addToBaseURL";

export async function getChargeableProjects(
  from: string,
  to: string
): Promise<any> {
  const url = addToBaseURL(
    `/projects?startDate=${from}&endDate=${to}&chargeable=true`
  );
  const response = await axios.get(url);
  return response.data;
}
