import axios from "axios";
import { addToBaseURL } from "@/test-utils/addToBaseURL";

export interface Employee {
  confirmationTimestamp: number;
  employeeNumber: string;
  firstName: string;
  id: number;
  lastName: string;
  scope: string;
  targetTime: number;
}

export async function getLastConfirmationTime(): Promise<any> {
  const url = addToBaseURL(`/employees/me/lastConfirmationTime`);
  const response = await axios.get(url);
  return response.data;
}
