import axios from "axios";
import { addToBaseURL } from "@/test-utils/addToBaseURL";

export async function postCSVFile(
  origin: string,
  formData: FormData,
  datePicker?: string
): Promise<any> {
  let url = addToBaseURL(`/database/import?origin=${origin}`);
  if (datePicker) {
    url = url + "&since=" + datePicker;
  }
  const response = await axios.post(url, formData);
  return response.data;
}
