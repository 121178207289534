import axios from "axios";
import { addToBaseURL } from "@/test-utils/addToBaseURL";

export interface Employee {
  confirmationTimestamp: number;
  employeeNumber: string;
  firstName: string;
  id: number;
  lastName: string;
  scope: string;
  targetTime: number;
}

export async function postDatabaseUpdate(): Promise<any> {
  const url = addToBaseURL(`/database/update`);
  const response = await axios.post(url, undefined);
  return response.data;
}
