import axios from "axios";
import { addToBaseURL } from "@/test-utils/addToBaseURL";

export async function postConfirmWorkedTime(confirmTime: number): Promise<any> {
  const url = addToBaseURL(
    `/employees/me/confirmWorkedTime?timestamp=${confirmTime}`
  );
  const response = await axios.post(url, {});
  return response.data;
}
