import axios from "axios";
import { addToBaseURL } from "@/test-utils/addToBaseURL";

export interface Employee {
  confirmationTimestamp: number;
  employeeNumber: string;
  firstName: string;
  id: number;
  lastName: string;
  scope: string;
  targetTime: number;
}

export async function getEmployeeChargeabilities(
  from: string,
  to: string,
  granularity: string
): Promise<any> {
  const url = addToBaseURL(`/chargeability/all`);
  const response = await axios.get(url, {
    params: {
      startDate: from,
      endDate: to,
      granularity: granularity,
    },
  });
  return response.data;
}
