import axios from "axios";
import { addToBaseURL } from "@/test-utils/addToBaseURL";

export async function getChartData(
  employeeId: string | number,
  from: string,
  to: string,
  granularity: string
): Promise<any> {
  const url = addToBaseURL(
    `/times/${employeeId.toString()}?startDate=${from}&endDate=${to}&granularity=${granularity}`
  );
  const response = await axios.get(url);
  return response.data;
}
