import axios from "axios";
import { addToBaseURL } from "@/test-utils/addToBaseURL";

export interface Employee {
  confirmationTimestamp: number;
  employeeNumber: string;
  firstName: string;
  id: number;
  lastName: string;
  scope: string;
  targetTime: number;
}

interface GetDatabaseStatusResponse {
  id: string;
  message: string;
  nextUpdate: number;
  lastUpdate: number;
  lastDuration: number;
  running: boolean;
}

export async function getDatabaseStatus(): Promise<GetDatabaseStatusResponse> {
  const url = addToBaseURL(`/database/status`);
  const response = await axios.get(url);
  return response.data;
}
