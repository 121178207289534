import axios from "axios";
import { addToBaseURL } from "@/test-utils/addToBaseURL";

export interface Employee {
  confirmationTimestamp: number;
  employeeNumber: string;
  firstName: string;
  id: number;
  lastName: string;
  scope: string;
  targetTime: number;
}

interface GetChargeOverviewResponse {
  averageChargeability: number;
  averageChargeabilityBrutto: number;
  chargedTime: number;
  employee: Employee;
  loggedTime: number;
  targetTime: number;
  targetTimeBrutto: number;
}
export async function getChargeOverview(
  id: string,
  from: string,
  to: string
): Promise<GetChargeOverviewResponse> {
  const url = addToBaseURL(`/chargeability${id}`);
  const response = await axios.get(url, {
    params: {
      startDate: from,
      endDate: to,
    },
  });
  return response.data;
}
